@use "../../scss/" as *;
.react-tabs__tab-list {
  @include flex(center, left);
  margin-bottom: 39px;
  @include desktop-1200 {
    flex-wrap: wrap;
    li {
      margin-top: 10px;
    }
  }
  li {
    padding: 7.5px 16px;
    margin-right: 14.5px;
    border-radius: 100px;
    border: 1px solid var(--primary-color36);
    background-color: var(--primary-color22);
    color: #565660;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1em;
    @include transition3;
    cursor: pointer;

    &.react-tabs__tab--selected,
    &:hover {
      background-color: #3749e9;
      border-color: #3749e9;
      color: #fff;
    }
  }
}

.tf-center .react-tabs__tab-list {
  justify-content: center;
  li {
    border-radius: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.dropdown .dropdown-toggle {
  background-color: var(--primary-color6);
  border-color: var(--primary-color36);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--primary-color5);
}

.show > .btn-primary.dropdown-toggle {
  background-color: var(--primary-color6);
  border-color: var(--primary-color36);
  box-shadow: none;
  outline: none;
  color: var(--primary-color5);
}

.dropdown-menu {
  background-color: var(--primary-color6);
  border-color: var(--primary-color36);
  border-radius: 10px;
  width: 100%;

  .dropdown-item {
    background: transparent;
    padding: 0px 10px;
  }
}

.sidebar-explore .dropdown .dropdown-toggle {
  text-align: left;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid var(--primary-color47);
}

.custom-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 1920px;
  // height: 1080px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  opacity: 0;
  animation: modalFadeIn 0.3s ease-in-out forwards;

  @keyframes modalFadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.9);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .btn-close {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    background-color: transparent;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;

    .x {
      width: 50px;
      height: 50px;
    }
  }

  .custom-modal-inside {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    width: 414px;
    height: 90%;
    overflow-y: auto; /* เพิ่มตัวเลื่อนแนวตั้งเมื่อเนื้อหายาวเกิน */
    padding: 20px;
    background-color: rgba(25, 24, 32, 0.9); /* ใช้ rgba แทน */
    // border: 5px solid #800080;
    border-radius: 20px; 
    position: relative; 
    box-shadow: 0 0 5px 2px #48abe0, 0 0 10px 3px #8a2be2, 0 0 7px 3px #ff1493;
    animation: glowing 2s infinite alternate ease-in-out; 
    scrollbar-width: thin;
    scrollbar-color: #a335a9 #1b1c1d; 

    @keyframes glowing {
      0% {
        box-shadow: 0 0 5px 2px #48abe0, 0 0 10px 3px #8a2be2,
          0 0 7px 3px #ff1493;
      }
      50% {
        box-shadow: 0 0 20px 5px #48abe0, 0 0 30px 10px #8a2be2,
          0 0 15px 5px #ff1493; /* แสงที่สว่างขึ้น */
      }
      100% {
        box-shadow: 0 0 5px 2px #48abe0, 0 0 10px 3px #8a2be2,
          0 0 7px 3px #ff1493;
      }
    }

    .promo-pic {
      width: 350px;
      height: 400px;
      padding: 20px;
    }

    .title {
      font-family: "DM Sans";
      font-weight: 700;
      font-size: 23px;
      text-align: center;
      text-shadow: -10px -1px 7px rgba(223, 185, 255, 0.92);
      color: #ffffff;
      text-shadow: 0 0 10px #ffffff;
    }

    .desc {
      font-family: "DM Sans";
      font-weight: 700;
      font-size: 15px;
      padding: 15px;
      flex: 1; 
    }

    .btn-promo-container {
      bottom: 0px;
      margin-top: 20px;
      padding-bottom: 20px;
      display: flex;
      cursor: pointer;

      &:hover {
        text-shadow: -10px -1px 7px rgba(223, 185, 255, 0.92);
        color: #ffffff;
        text-shadow: 0 0 10px #ffffff;
      }
    }
  }
}
